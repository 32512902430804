import { Box, IconButton, useMediaQuery } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "../../components/Link";
import GoogleButton from "../../components/oidc/GoogleButton";
import { useUserContext } from "../../context/UserContext";
import { useOurRouter } from "../../hooks/useOurRouter";
import LogoSvg from "../../img/reclaimLogoOnDark.svg";
import { reclaim } from "../../reclaim-api/index";
import { QueryState } from "../../types/query";
import {
  getLocalStorage,
  getSessionStorage,
  removeLocalStorage,
  removeSessionStorage
} from "../../utils/local-storage";
import { browser } from "../../utils/platform";
import LandingLayout from "../LandingLayout";

const useStyles = makeStyles(
  (theme) => ({
    body: {
      margin: theme.spacing(4, 0),
    },
    o365Callout: {
      backgroundColor: "rgba(47, 46, 65, .5)",
      borderRadius: 4,
      margin: theme.spacing(5, "auto", 0),
      maxWidth: 400,
      padding: theme.spacing(2),
      "& a": {
        color: theme.colors.logo.shrimp,
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    fullWidth: {
      flex: "0 1 auto", //keep to fix safari bug
      width: "100%",
    },
    gridContainer: {
      backgroundColor: theme.colors.logo.darkness,
      color: theme.colors.white,
      height: "100vh",
      padding: theme.spacing(2),
      width: "100vw",
    },
    wrapper: {
      textAlign: "center",
      maxWidth: 600,
      width: "100%",
    },
    logoBtn: {
      display: "block",
    },
    logoSvg: {
      height: "auto",
      marginBottom: theme.spacing(2),
      maxWidth: 200,
      width: "100%",
    },
    heading: {
      marginBottom: theme.spacing(3),
    },
    googleBtn: {
      margin: "0 auto",
      maxWidth: 360,
      transform: "scale(1)",
      transition: "transform .2s ease",
      width: "100%",
      "&:hover": {
        transform: "scale(1.02)",
      }
    },
    signupText: {
      marginTop: theme.spacing(4),
      "& a": {
        color: theme.colors.logo.shrimp,
        fontWeight: theme.typography.fontWeightMedium,
      }
    }
  }),
  { index: 2, name: "LoginTemplate" }
);

export type LoginLayoutProps = {
  title: string;
  displayLoginButton: boolean;
  officeNotice?: boolean;
};

export const LoginLayout: React.FC<LoginLayoutProps> = ({
  title,
  displayLoginButton,
  officeNotice,
  children,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const medium = useMediaQuery(theme.breakpoints.down("md"));

  const router = useOurRouter<{ reason: string; state: QueryState }>();

  const [{ user, isAuthenticated, status }] = useUserContext();

  const [error, setError] = useState<string | undefined>();

  const urlParams = useMemo(() => new URLSearchParams(browser().isBrowser ? window.location.search : ""), []);

  useEffect(() => {
    if (!router.query?.reason) return;
    const reason = router.query.reason;

    setError(
      "USER_DISABLED" === reason
        ? "Calendar for this email address is linked to another account. Please sign in using your other Google login."
        : "User not authorized."
    );
  }, [router.query?.reason]);

  // Redirect if user is already authenticated
  useEffect(() => {
    if (!router.isReady || !isAuthenticated) return;

    removeLocalStorage("auth.reauth");
    removeLocalStorage("auth.attempts");

    const slackTeamId = urlParams.get("slackTeamId");
    const slackUserId = urlParams.get("slackUserId");

    // the check for "://" prevents arbitrary redirection (Open Redirect)
    const oauthRedirect = router.query.state?.redirect;
    const redirect =
      !!oauthRedirect && !oauthRedirect.includes("://") ? oauthRedirect : getSessionStorage("auth.redirect");
    const hasSharedHabit = !!getLocalStorage("habits.sharedPreset");

    if (redirect) {
      removeSessionStorage("auth.redirect");
    }

    // connect slack user
    if (slackUserId && slackTeamId) {
      reclaim.slack.link(slackUserId, slackTeamId).catch((reason) => {
        if (404 === reason.status) {
          console.log("Slack user not found, redirecting", reason);
          reclaim.slack.authRedirect(slackTeamId);
          return;
        } else {
          console.error("Failed to link slack user", reason);
        }
      });
    }

    let pathname = "/onboarding/welcome";

    if (user?.onboarded) {
      if (hasSharedHabit) pathname = "/habits";
      else if (redirect) pathname = redirect;
      else if (medium) pathname = "/stats";
      else pathname = "planner";
    }

    void router.push({
      pathname,
      query: router.query,
    });
  }, [isAuthenticated, medium, router, urlParams, user?.onboarded]);

  const handleClick = useCallback(
    (provider: string) => {
      removeLocalStorage("auth.reauth");
      removeLocalStorage("auth.attempts");

      const storedUser = getLocalStorage("auth.user");
      const ref = getLocalStorage("auth.ref");
      const utm = getLocalStorage("auth.utm");

      const slackTeamId = urlParams.get("slackTeamId");
      const slackUserId = urlParams.get("slackUserId");

      const invite = urlParams.get("invite");

      // the check for "://" prevents arbitrary redirection (Open Redirect)
      const oauthRedirect = router.query.state?.redirect;
      const redirect = `${
        !!oauthRedirect && !oauthRedirect.includes("://") ? oauthRedirect : getSessionStorage("auth.redirect", "/")
      }${!!invite ? "?invite=1" : ""}`;

      removeSessionStorage("auth.redirect");

      const state = {
        flow: "default",
        redirect,
        slackTeamId,
        slackUserId,
        ref,
        utm,
      };

      reclaim.users.authRedirect(provider, storedUser?.email, state);
    },
    [router.query.state?.redirect, urlParams]
  );

  return (
    <LandingLayout title={title} loading={["init", "loading"].includes(status) || !!isAuthenticated} hideNav>
      <Grid container className={classes.gridContainer} justifyContent="center" alignItems="center">
        <Box className={classes.wrapper}>
          <IconButton
            className={classes.logoBtn}
            component={Link}
            href={!!user ? "/" : "//reclaim.ai"}
            size="medium"
            aria-label="reclaim.ai"
            disableTouchRipple
            disableRipple
            disableFocusRipple
          >
            <LogoSvg className={classes.logoSvg} />
          </IconButton>
          {children}
          {!!displayLoginButton && (
            <GoogleButton
              label="Continue with Google"
              onClick={() => handleClick("google")}
              className={classes.googleBtn}
            />
          )}
          {!!error && (
            <Typography variant="body1" color="error" className={classes.body}>
              {error}&nbsp; If you believe this to be an error, please contact support.
            </Typography>
          )}
          {!!officeNotice && (
            <Typography variant="body2" className={classes.o365Callout}>
              At this time, Reclaim only works on Google Calendar. Interested in Office 365?{" "}
              <Link href="mailto:hello@reclaim.ai?subject=Re:%20Office%20365%20Support%20for%20Reclaim&body=Hi%20Reclaim%20Team!%0D%0A%0D%0AI'm%20an%20Office%20365%20user%20and%20would%20love%20to%20stay%20updated%20when%20Reclaim%20works%20for%20O365%20calendars.%0D%0A%0D%0AThanks!">
                Let&nbsp;us&nbsp;know
              </Link>
            </Typography>
          )}
          {!!displayLoginButton && (
            <>
              {router.pathname.startsWith("/login") ? (
                <Typography className={classes.signupText} variant="body2">
                  Don't have an account? <Link href="/signup">Sign up</Link>
                </Typography>
              ) : (
                <Typography className={classes.signupText} variant="body2">
                  Have an account? <Link href="/login">Log in</Link>
                </Typography>
              )}
            </>
          )}
        </Box>
      </Grid>
    </LandingLayout>
  );
};
